import request from "../utils/request";

// 分页获取字典列表
export function queryPage(params) {
    return request({
        url: "/vc-phone-time",
        method: "GET",
        params
    })
}

// 添加字典
export function save(params) {
    return request({
        url: "/vc-phone-time",
        method: "POST",
        data: params
    })
}

// 修改数据
export function editRequest(params) {
    return request({
        url: "/vc-phone-time",
        method: "PUT",
        data: params
    })
}

// 删除字典
export function delPhone(id) {
    return request({
        url: "/vc-phone-time/" + id,
        method: "DELETE"
    })
}
