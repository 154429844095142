<template>
  <div>

    <el-row>
      <el-col :span="24">
        <div class="tools">
          <el-button @click="handleAdd" size="mini" icon="el-icon-plus" type="primary">新增</el-button>
          <el-button :loading="false" :disabled="false" size="mini" icon="el-icon-refresh" @click="refresh()">刷新</el-button>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                prop="phone"
                label="手机号"
                width="250">
            </el-table-column>
            <el-table-column
                prop="endTime"
                label="有效期"
                width="250">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="最后一次操作时间"
                width="250">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-delete"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>

                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-edit"
                    @click="handleEdit(scope.$index, scope.row)">修改
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        destroy-on-close
        width="50%">
      <el-form ref="addForm" :inline="true" :model="addInfo" :rules="rules">
        <el-form-item class="text" label="手机号" prop="phone">
          <el-input
              placeholder="手机号"
              v-model="addInfo.phone"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="有效期" prop="endTime">
          <el-date-picker
              v-model="addInfo.endTime"
              type="datetime"
              format="yyyy-MM-dd HH:ss:mm"
              value-format="yyyy-MM-dd HH:ss:mm"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addDict()">确 定</el-button>
  </span>
    </el-dialog>

  <el-dialog
        title="编辑"
        :visible.sync="editDialogVisible"
        destroy-on-close
        width="50%">
      <el-form ref="editForm" :inline="true" :model="editDictInfo" :rules="rules">
        <el-form-item class="text" label="手机号" prop="phone">
          <el-input
              placeholder="手机号"
              v-model="editDictInfo.phone"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="结束时间" prop="endTime">
          <el-date-picker
              v-model="editDictInfo.endTime"
              type="datetime"
              format="yyyy-MM-dd HH:ss:mm"
              value-format="yyyy-MM-dd HH:ss:mm"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editDict()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import { save } from '@/api/phone.js'
import { delPhone } from '@/api/phone.js'
import { editRequest } from '@/api/phone.js'
import {queryPage} from '@/api/phone.js'

export default {
  name: "Phone",
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"))
      } else if (!/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"))
      } else {
        callback()
      }
    };
    return {
      rules: {
        phone: [{ validator: checkPhone, required: true, trigger: 'blur' }],
        endTime: [{ message: '请选择有效期', required: true, trigger: 'blur' }],
      },
      addInfo: {
        endTime: '',
        phone: '',
      },
      editDictInfo: {
        id: '',
        endTime: '',
        phone: '',
      },
      tableData: [],
      queryInfo: {
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  created () {
      this.refresh();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    refresh() {
      queryPage(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    clear(){
      this.addInfo = {
        phone: '',
        endTime: '',
      }
    },
    addDict() {
      this.$refs.addForm.validate((valid) => {    // 为表单绑定验证功能
        if (valid) {
          save(this.addInfo).then((res) => {
            if (res.code === 0) {
              this.$message.success("添加成功")
              this.addDialogVisible = false
              // 添加成功
              this.refresh();
              this.clear();
            } else {
              // 添加失败
              this.$message.error(res.msg)
            }
          })
        }
      })

    },
    editDict() {
      this.$refs.editForm.validate((valid) => {    // 为表单绑定验证功能
        if (valid) {
          const params = {
            phone: undefined,
            endTime: undefined,
            id: undefined
          }

          if (this.editDictInfo.phone == '' || this.editDictInfo.endTime == '') {
            this.$message.warning("手机号或者有效期不能为空!");
            return;
          }


          if (this.editDictInfo.phone !== '') {
            params.phone = this.editDictInfo.phone
          }
          if (this.editDictInfo.endTime !== '') {
            params.endTime = this.editDictInfo.endTime
          }
          if (this.editDictInfo.id !== '') {
            params.id = this.editDictInfo.id
          }


          editRequest(params).then((res) => {
            if (res.code === 0) {
              this.$message.success("修改成功")
              this.editDialogVisible=false;
              this.refresh()
              this.clear();
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });



    },
    handleEdit(index, row) {
      this.editDictInfo.phone = row.phone
      this.editDictInfo.endTime = row.endTime
      this.editDictInfo.id = row.id
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认删除此项数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delPhone(row.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("删除成功!")
            this.refresh();
          } else {
            this.$message.error(res.msg)
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh();
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
